import React from "react";
import { SEO, PageLayout, Contact, ContactForm } from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";
import "../style/contact.css";

export default class ContactPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout hero={false}>
          <Grid stackable={true} className={"component-section-container"}>
            <Grid.Column width={16}>
              <h2 className={"contact-section-title"}>
                Cajun Crab House Seafood Market
              </h2>
              <Contact
                businessName={"Cajun Crab House Seafood Market"}
                phoneNumber={"573-442-6562"}
                email={""}
                hours={null}
                address={{
                  street: "904 Interstate 70 Dr SW",
                  city: "Columbia",
                  state: "MO",
                  zip: "65203",
                }}
                as={"text"}
                buttonColor={"secondary"}
                header={"Contact"}
                image={""}
                showMap={true}
              />
            </Grid.Column>
            <Grid.Column width={16}>
              <h2 className={"contact-section-title"}>
                Cajun Crab House Seafood Restaurant
              </h2>
              <Contact
                businessName={fishermanBusiness.name}
                phoneNumber={fishermanBusiness.primaryLocation.phoneNumber}
                email={fishermanBusiness.primaryLocation.email}
                hours={fishermanBusiness.primaryLocation.hours}
                address={{
                  street: fishermanBusiness.primaryLocation.street,
                  city: fishermanBusiness.primaryLocation.city,
                  state: fishermanBusiness.primaryLocation.state,
                  zip: fishermanBusiness.primaryLocation.zipCode,
                }}
                as={"text"}
                buttonColor={"secondary"}
                header={"Contact"}
                image={""}
                showMap={true}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"component-section-container"}
          >
            <Grid.Column computer={12} mobile={16}>
              <ContactForm
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={"Contact Us"}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        phoneNumber
        email
        hours {
          open
          close
          label
          day
        }
        street
        city
        state
        zipCode
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(title: { eq: "Contact" }) {
      title
      components {
        fastId
        order
        files {
          file
        }
      }
    }
  }
`;
